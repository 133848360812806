import { Radio, Space, Typography } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Field } from '../fields';
import { Box } from '../box';
import { Icon, Icons } from '../icons';

export const PaymentMethod = () => {
  const { t } = useTranslation('translation');
  const { t: tErr } = useTranslation('errors');

  return (
    <Field
      name="paymentType"
      label={t('payment.method')}
      rule={[{ required: true, message: tErr('form.payment-method.required') }]}
    >
      <RadioGroup>
        <Space direction="vertical">
          <Radio value="CARD">
            <Box $align="center" $justify="space-between">
              <Typography>{t('payment.card')}</Typography>

              <Box
                as="img"
                src="/images/card.png"
                $height="18px"
                $width="64px"
              />
            </Box>
          </Radio>

          <Radio value="CASH">
            <Box $align="center" $justify="space-between">
              <Typography>{t('payment.cash')}</Typography>

              <Icon as={Icons.Cash} />
            </Box>
          </Radio>
        </Space>
      </RadioGroup>
    </Field>
  );
};

const RadioGroup = styled(Radio.Group)`
  width: var(--full);

  .ant-radio-wrapper {
    width: var(--full);

    span:nth-of-type(2) {
      width: var(--full);
    }
  }

  .ant-space-item {
    border-radius: var(--ss);
    border: 1px solid #e5e7eb;
    padding: var(--lg) var(--xs);
  }
`;
