export enum Endpoints {
  // AUTH
  DeleteAccount = 'delete-account',
  Sign = '',
  GetMe = 'get-me',
  Otp = 'send-otp',
  Verify = 'verify-otp',
  Logout = 'v1/device/logout-user-from-device',

  // Welcome
  GetAmount = 'payment/get-fill-balace-amount',
  Reg = 'payment/fill-balance-reg',

  // Tickets
  TicketOffers = 'flights',
  TicketClasses = 'flights/classes',
  SearchAirline = 'flights/search-airline',

  // PROFILE
  UpdateUser = 'update-user-info',
  UpdateProfileImage = 'update-profile-image',
  DeleteProfileImage = 'delete-profile-image',
  FillBalance = 'payment/fill-balance',
  Orders = 'orders',
  AccountHistory = 'user-account/history',
  UpdateDeviceLanguage = 'v1/device/update-device-language',

  // TOURS
  TourCountries = 'tours/countries',
  ToursGroup = 'tours/group-tours',
  ToursIndividual = 'tours/individual-tours',
  ByCountry = 'country',
  CreateTourOder = 'orders/tour',
  BookTour = 'payment/test/group-tour',

  // transfer
  Transfer = 'transfer/cars/category',
  CreateTransfer = 'orders/transfer',
  PaymentTransfer = 'payment', // live mode
  // PaymentTransfer = 'payment/v2', // test mode
  TransferCategory = 'transfer/categories/with-cars',
  TransferBoundaries = 'transfer/boundaries',
  RouteExists = 'transfer/directions/source-boundary',

  // HOTELS
  HotelAutoComplete = 'hotels/autocomplete',
  SearchHotels = 'v2/hotels/search',
  HotelDetail = 'hotels/detail',
  HotelSelectRoom = 'v2/hotels/select-room',
  HotelPreBook = 'v2/hotels/pre-book',
  HotelOrder = 'v2/hotels/order',

  // FEEDBACK
  FeedbacksCreate = 'feedback',
  Feedbacks = 'feedback/product',
  Rating = 'feedback/rating',

  // UPLOAD
  UploadFile = 'upload/file',
}
